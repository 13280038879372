import DbItem from './db-item';

export const IncomeFrequency = [
  'fortnightly',
  'four-weekly',
  'monthly',
  // Deprecated, use monthly
  'monthly-by-date',
  // Deprecated, use four-weekly
  'monthly-by-day',
  'weekly',
] as const;

export const UserStatus = ['active', 'deleted', 'dishonoured', 'inactive', 'on_hold', 'rejected', 'suspended'] as const;

interface UserStats {
  transactionNextAmount: number;
  transactionNextAt: string;
  transactionNextId: string;
  updatedAt: string;
}

interface UserBankAccount {
  accountNumberLastDigits: string;
  bankName: string;
}

interface UserAttributes extends DbItem {
  balanceAvailable: number;
  balanceBook: number;
  balanceBookPayable: number;
  balanceCredit: number;
  balanceCreditMax: number;
  balanceCurrent: number;
  balanceFee: number;
  balanceFeePayable: number;
  balanceLimit: number;
  balanceLimitMax?: number;
  balanceLimitMin: number;
  balanceOverdue: boolean;
  balanceOverdueAt?: string | null;
  balancePayable: number;
  bankAccount?: UserBankAccount;
  bankAccountId?: string;
  bankDataEnabled?: boolean;
  bankDataRequired?: boolean;
  bankDataRefreshPausedUntil?: string | null;
  collectionReminderPausedUntil?: string | null;
  debitCardId?: string;
  dob: string;
  email: string;
  firstName: string;
  identityId?: string;
  incomeFrequency?: (typeof IncomeFrequency)[number];
  incomeNextDate?: string;
  lastName: string;
  meta?: Record<string, unknown>;
  middleName?: string;
  mobileNumber: string;
  paymentAccountId?: string;
  paymentUrl?: string | null;
  postcode?: string;
  prettyDob?: string;
  repaymentNextAmount?: number;
  repaymentNextAt?: string;
  repaymentPlanId?: string | null;
  riskAssessmentId?: string;
  riskAssessmentAppliedUntil?: string | null;
  riskAssessmentPausedUntil?: string | null;
  stats?: UserStats;
  status: (typeof UserStatus)[number];
  statusActiveAt?: string;
  statusReason: string;
  transactionLastAt?: string;
  transactionLastId?: string;
}

export default UserAttributes;

export function isCreditAvailableForAmount(user: UserAttributes, amount: number): boolean {
  const { balanceCredit, balanceCreditMax } = user;
  const balanceCreditInCents = Math.round(balanceCredit * 100);
  const balanceCreditMaxInCents = Math.round(balanceCreditMax * 100);
  return Math.round(Number(amount) + balanceCreditInCents) <= balanceCreditMaxInCents;
}
